





import { Component, Vue, Ref, Watch, Mixins } from 'vue-property-decorator'

export default class StudentSchedule extends Vue {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private sharedScheduleUrl =
    process.env.VUE_APP_MANAMIRU_URL +
    '/teacher?' +
    'branchId=' +
    Vue.prototype.$cookies.get('dataGdls').branchId +
    '&&access-token=' +
    Vue.prototype.$cookies.get('authGdls').accessToken +
    '&&client=' +
    Vue.prototype.$cookies.get('authGdls').client +
    '&&uid=' +
    Vue.prototype.$cookies.get('authGdls').uid
  private check = this.checkPermission()
  private checkPermission() {
    Vue.prototype.$http.httpWithToken.get(`/v3/enabledShowCalender?branch_id=${this.branchId}`).then((res: any) => {
      if (!res.data.showCalendarAndGradeBook) {
        this.$router.push('/teacher/dashboard')
      }
    })
  }
}
